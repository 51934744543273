import { pagesPaths } from 'common/router/routes.utils'
import Link from 'next/link'
import styled from 'styled-components'
import SeaplifyLogo from '../../../../assets/LogoBlue.svg'
import AssistLogo from '../../../../assets/assistLogo.svg'
import PartnerLogo from '../../../../assets/partnerLogo.svg'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}
export const AuthLayout = ({ children }: Props) => {
  return (
    <>
      <Link href={pagesPaths.root} className="cursor-pointer" data-test="mainLogo">
        <StyledLogo />
      </Link>
      <Div>{children}</Div>
      <div data-test="logos" className="flex flex-column align-items-center gap-3">
        <AssistLogo />
        <PartnerLogo />
      </div>
    </>
  )
}

const StyledLogo = styled(SeaplifyLogo)`
  width: 295px;
  height: 23px;
`

const Div = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
`
