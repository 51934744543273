import styled from 'styled-components'
type Props = {
  children: React.ReactNode | React.ReactNode[]
  className?: string
}
const AuthContainer = ({ children, className }: Props) => <Div className={className}>{children}</Div>
const Div = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  width: 440px;
  margin: 0 auto;
  padding-top: 10vh;
  padding-bottom: 6vh;
`
export default AuthContainer
